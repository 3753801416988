import { alpha, darken } from '@theme-ui/color'

const heading = {
  textTransform: 'uppercase'
}

const theme = {
  fonts: {
    body: 'Nunito, sans-serif',
    heading: 'Jost, sans-serif'
  },
  lineHeights: {
    body: 1.4,
    paragraph: 1.2
  },
  colors: {
    text: '#e6e6e6',
    background: '#1e292d',
    primary: '#e6e6e6',
    secondary: '#ff6347',
    highlight: '#e6e6e6',
    muted: alpha('background', 0.9),
    disabled: darken('text', 0.5)
  },
  sizes: {
    container: 1140
  },
  text: {
    heading: heading,
    paragraph: {
      lineHeight: 'paragraph'
    }
  },
  badges: {
    primary: {
      color: 'background',
      textTransform: 'uppercase',
      fontSize: 2,
      px: 2
    }
  },
  forms: {
    select: {
      pr: 28,
      width: 'inherit'
    }
  },
  styles: {
    h1: heading,
    h2: heading,
    h3: heading
  }
}

// defaultBreakpoints : 40, 52, 64

export default theme;
